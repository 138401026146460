<template>
	<div>
		<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
	</div>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";

export default {
	name: "AnalyzingPrimarySources",

	data() {
		return {
			options: [
				{
					title: "Primary Sources Databases",
					icon: "database",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.integrated_analysis.title",
					icon: "integrating",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_picture.title",
					icon: "analyzing_picture",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_poster.title",
					icon: "analyzing_poster",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_menu_script.title",
					icon: "analyzing_manuscript",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_document.title",
					icon: "analyzing_document",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_video.title",
					icon: "analyzing_video",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_article.title",
					icon: "article",
					route: null,
					fade: true,
				},
				{
					title: "app.ko.history.analyzing_primary_sources.analyzing_ad.title",
					icon: "analyzing_ad",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analyzing_recording.title",
					icon: "analyzing_recording",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.persona_analysis.title",
					icon: "persona",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analysis_by_event.title",
					icon: "analysis_by_event",
					route: null,
					fade: true,
				},
				{
					title:
						"app.ko.history.analyzing_primary_sources.analysis_by_location.title",
					icon: "analyzing_location",
					route: null,
					fade: true,
				},
			],
		};
	},

	components: {
		AppBoxTheme,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
